import { Box, SystemProps, Flex } from "@storyofams/react-ui";
import Image from "next/image";

import { getLinkProps } from "~lib";

import { Button } from "../../Button";
import { Text } from "../../Text";

type SmallBannerProps = {
  first?: boolean;
  content: {
    button_text?: string;
    button_link: any;
    title?: string;
    description: string;
    image?: {
      filename: string;
    };
  };
} & SystemProps;

export const SmallBanner = ({ content, first, ...props }: SmallBannerProps) => {
  return (
    <Box
      backgroundColor={"#F4F3F0"}
      borderRadius={"8px"}
      display={"flex"}
      width={"100%"}
      p={["20px", "32px"]}
      alignItems={["start", "center"]}
      flexDirection={["column", "row"]}
    >
      <Flex
        width={"100%"}
        alignItems={["start", "center"]}
        flexDirection={["column", "row"]}
      >
        <Box
          width={"132.25px"}
          height={"132.25px"}
          position={"relative"}
          borderRadius={"full"}
          flexShrink={0}
          overflow={"hidden"}
        >
          <Image objectFit="cover" layout="fill" src={content.image.filename} />
        </Box>

        <Box maxWidth={"776px"} ml={[0, 4]} mt={[3, 0]}>
          <Text
            fontWeight={700}
            fontFamily={"DINPro"}
            fontSize={["23px", "36px"]}
            lineHeight={["28.8px", "43.2px"]}
            mb={2}
          >
            {content.title}
          </Text>
          <Text
            color={"#1D1D1B"}
            fontSize={["16px", "18px"]}
            lineHeight={["22.4px", "25.2px"]}
            mb={[3, 0]}
          >
            {content.description}
          </Text>
        </Box>
      </Flex>

      <Button
        href={content.button_link.url}
        fontSize={"18px"}
        height={"57px"}
        width={["100%", "max-content"]}
        whiteSpace={"nowrap"}
        variant="primary"
      >
        {content.button_text}
      </Button>
    </Box>
  );
};
